/* Main App Styles */
.App {
  text-align: center;
}

/* Hero Section */
.hero {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  overflow: hidden;
  background-color: var(--dark);
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  padding: 0 var(--spacing-lg);
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hero-title {
  font-size: 3.5rem;
  margin-bottom: var(--spacing-md);
  animation: fadeInDown 1s ease-out;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: var(--spacing-lg);
  animation: fadeInUp 1s ease-out 0.3s;
  animation-fill-mode: both;
}

.hero-cta {
  animation: fadeIn 1s ease-out 0.6s;
  animation-fill-mode: both;
}

/* About Section */
.about-section {
  padding: var(--spacing-xxl) 0;
  background-color: var(--light);
}

.about-image-container {
  position: relative;
  margin-bottom: var(--spacing-lg);
}

.about-image {
  border-radius: 50%;
  box-shadow: var(--shadow);
  transition: all 0.5s ease;
  filter: saturate(0.8);
}

.about-image:hover {
  transform: scale(1.05);
  filter: saturate(1.2);
  box-shadow: var(--shadow-lg);
}

.about-content {
  padding: var(--spacing-lg);
}

.about-title {
  margin-bottom: var(--spacing-lg);
  position: relative;
  display: inline-block;
}

.about-title:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 50px;
  height: 3px;
  background-color: var(--accent);
}

.about-text {
  margin-bottom: var(--spacing-lg);
  font-size: 1.1rem;
  line-height: 1.8;
}

/* Experience Section */
.experience-section {
  padding: var(--spacing-xxl) 0;
  background-color: var(--gray-100);
}

.experience-item {
  margin-bottom: var(--spacing-xl);
  padding: var(--spacing-lg);
  background-color: white;
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.experience-item:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.experience-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.experience-title {
  color: var(--primary);
  margin-bottom: var(--spacing-xs);
}

.experience-company {
  color: var(--secondary);
  font-weight: 500;
  margin-bottom: var(--spacing-xs);
}

.experience-date {
  color: var(--gray-600);
  font-style: italic;
}

.experience-description {
  margin-bottom: var(--spacing-md);
}

.experience-skills {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
}

.skill-tag {
  background-color: var(--gray-200);
  color: var(--gray-800);
  padding: 0.25rem 0.75rem;
  border-radius: 50px;
  font-size: 0.85rem;
  transition: background-color 0.3s ease;
}

.skill-tag:hover {
  background-color: var(--secondary);
  color: white;
}

/* Education Section */
.education-section {
  padding: var(--spacing-xxl) 0;
  background-color: white;
}

.education-item {
  margin-bottom: var(--spacing-lg);
  padding-bottom: var(--spacing-lg);
  border-bottom: 1px solid var(--gray-300);
}

.education-item:last-child {
  border-bottom: none;
}

.education-degree {
  color: var(--primary);
  margin-bottom: var(--spacing-xs);
}

.education-institution {
  color: var(--secondary);
  font-weight: 500;
  margin-bottom: var(--spacing-xs);
}

.education-date {
  color: var(--gray-600);
  font-style: italic;
}

/* Blog Section */
.blog-section {
  padding: var(--spacing-xxl) 0;
  background-color: var(--gray-100);
}

.project-card {
  height: 100%;
  background-color: white;
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.project-content {
  padding: var(--spacing-lg);
}

.project-title {
  color: var(--primary);
  margin-bottom: var(--spacing-sm);
}

.project-description {
  margin-bottom: var(--spacing-md);
  color: var(--gray-700);
}

.project-tags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
  margin-bottom: var(--spacing-md);
}

/* Contact Section */
.contact-section {
  padding: var(--spacing-xxl) 0;
  background-color: var(--primary);
  color: white;
}

.contact-form {
  background-color: white;
  padding: var(--spacing-xl);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-lg);
}

.form-group {
  margin-bottom: var(--spacing-md);
}

.form-control {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid var(--gray-300);
  border-radius: var(--border-radius);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-control:focus {
  border-color: var(--secondary);
  box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.25);
  outline: none;
}

/* Footer */
.footer {
  padding: var(--spacing-xl) 0;
  background-color: var(--dark);
  color: white;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
}

.footer-link {
  color: var(--gray-400);
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: white;
}

.footer-social {
  display: flex;
  justify-content: center;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: white;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.social-icon:hover {
  background-color: var(--secondary);
  transform: translateY(-3px);
}

.footer-copyright {
  text-align: center;
  color: var(--gray-500);
  font-size: 0.9rem;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Utility Classes */
.fade-in {
  animation: fadeIn 1s ease-out;
}

.fade-in-up {
  animation: fadeInUp 1s ease-out;
}

.fade-in-down {
  animation: fadeInDown 1s ease-out;
}

.fade-in-left {
  animation: fadeInLeft 1s ease-out;
}

.fade-in-right {
  animation: fadeInRight 1s ease-out;
}

/* Animation Delays */
.delay-1 { animation-delay: 0.1s; }
.delay-2 { animation-delay: 0.2s; }
.delay-3 { animation-delay: 0.3s; }
.delay-4 { animation-delay: 0.4s; }
.delay-5 { animation-delay: 0.5s; }

/* Responsive Adjustments */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
  }
  
  .section {
    padding: var(--spacing-xl) 0;
  }
  
  .experience-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .experience-date {
    margin-top: var(--spacing-xs);
  }
}

/* Legacy Support */
.img-grayscale {
  filter: grayscale(0.5);
  width: auto;
  transition: filter 0.5s ease;
}

.img-grayscale:hover {
  filter: grayscale(0);
}

.caption-item {
  color: var(--secondary);
}

@media (min-width: 1250px) {
  .caption {
    position: absolute;
    top: 65%;
  }
}

@media (max-width: 1249px) {
  .caption {
    position: absolute;
    top: 35%;
  }
  .img-grayscale {
    margin-top: 76px;
  }
}

.img-tulsa {
  height: 25vh;
  border-radius: 10px;
  box-shadow: var(--shadow);
}

#about {
  line-height: 2.0rem;
}

.spacer {
  margin: 2em;
}

.ed-location, .cert-location, .pro-location {
  margin-bottom: 0px;
  color: var(--secondary);
  font-weight: 500;
}

.certs-container {
  background-image: linear-gradient(var(--light), var(--gray-400));
  padding: var(--spacing-xl) 0;
  border-radius: var(--border-radius-lg);
}

.hidden {
  display: none;
}

.btn-link {
  color: var(--gray-800) !important;
  transition: color 0.3s ease;
}

.btn-link:hover {
  color: var(--secondary) !important;
  text-decoration: none;
}

.navbar-link {
  color: var(--light) !important;
  transition: color 0.3s ease, transform 0.3s ease;
  position: relative;
}

.navbar-link:after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: var(--accent);
  transition: width 0.3s ease, left 0.3s ease;
}

.navbar-link:hover {
  color: var(--accent) !important;
  text-decoration: none;
  transform: translateY(-2px);
}

.navbar-link:hover:after {
  width: 100%;
  left: 0;
}

.carousel-animation {
  animation: carousel-fade 10s infinite;
}

@keyframes carousel-fade {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.2;
  }
  66% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.about-me-image {
  border-radius: 50%;
  box-shadow: var(--shadow);
  transition: all 0.5s ease;
}

.about-me-image:hover {
  transform: scale(1.05);
  box-shadow: var(--shadow-lg);
}

.about-me-text {
  animation: fadeIn 2s;
}

/* Resume Page Styles */
.resume-section {
  padding-top: 100px;
  padding-bottom: var(--spacing-xxl);
  background-color: var(--light);
}

.resume-header {
  text-align: center;
  margin-bottom: var(--spacing-xl);
}

.resume-name {
  font-size: 2.5rem;
  margin-bottom: var(--spacing-xs);
  color: var(--primary);
}

.resume-title {
  font-size: 1.5rem;
  margin-bottom: var(--spacing-lg);
  color: var(--secondary);
}

.resume-photo {
  border-radius: 50%;
  box-shadow: var(--shadow);
  transition: all 0.5s ease;
  max-width: 250px;
  margin: 0 auto var(--spacing-lg);
  border: 5px solid white;
}

.resume-photo:hover {
  transform: scale(1.05);
  box-shadow: var(--shadow-lg);
}

.resume-section-title {
  color: var(--primary);
  border-bottom: 2px solid var(--accent);
  padding-bottom: var(--spacing-xs);
  margin-bottom: var(--spacing-lg);
  font-weight: 700;
}

.resume-item {
  margin-bottom: var(--spacing-lg);
  padding-bottom: var(--spacing-lg);
  border-bottom: 1px solid var(--gray-300);
}

.resume-item:last-child {
  border-bottom: none;
}

.resume-item-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: var(--spacing-sm);
}

.resume-item-title {
  font-weight: 700;
  color: var(--primary);
  margin-bottom: 0;
}

.resume-item-date {
  color: var(--gray-600);
  font-style: italic;
}

.resume-item-subtitle {
  color: var(--secondary);
  font-weight: 500;
  margin-bottom: var(--spacing-sm);
}

.resume-item-description {
  margin-bottom: var(--spacing-sm);
}

.resume-item-skills {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
  margin-top: var(--spacing-sm);
}

.resume-download-btn {
  display: inline-block;
  margin-top: var(--spacing-lg);
  padding: var(--spacing-sm) var(--spacing-lg);
  background-color: var(--primary);
  color: white;
  border-radius: var(--border-radius);
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.resume-download-btn:hover {
  background-color: var(--secondary);
  transform: translateY(-3px);
}

.resume-contact {
  text-align: center;
  margin-top: var(--spacing-xl);
  padding-top: var(--spacing-lg);
  border-top: 1px solid var(--gray-300);
}

.resume-contact-item {
  display: inline-block;
  margin: 0 var(--spacing-md);
  color: var(--gray-700);
}

.resume-contact-item i {
  margin-right: var(--spacing-xs);
  color: var(--secondary);
}

/* Spinner Styles */
.spinner {
  margin: 100px auto;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: var(--secondary);
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
}
