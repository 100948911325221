:root {
  /* Premium color palette */
  --primary: #2c3e50;
  --secondary: #3498db;
  --accent: #e74c3c;
  --light: #ecf0f1;
  --dark: #1a252f;
  --success: #2ecc71;
  --info: #3498db;
  --warning: #f39c12;
  --danger: #e74c3c;
  --gray-100: #f8f9fa;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --gray-600: #6c757d;
  --gray-700: #495057;
  --gray-800: #343a40;
  --gray-900: #212529;
  
  /* Typography */
  --font-heading: 'Playfair Display', serif;
  --font-body: 'Roboto', sans-serif;
  
  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  --spacing-xxl: 3rem;
  
  /* Borders */
  --border-radius: 0.25rem;
  --border-radius-lg: 0.5rem;
  --border-width: 1px;
  
  /* Shadows */
  --shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: var(--font-body);
  font-weight: 400;
  line-height: 1.6;
  color: var(--gray-800);
  background-color: var(--light);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-heading);
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: var(--spacing-md);
  color: var(--primary);
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-bottom: var(--spacing-md);
}

a {
  color: var(--secondary);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--accent);
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.section {
  padding: var(--spacing-xxl) 0;
}

.section-title {
  text-align: center;
  margin-bottom: var(--spacing-xl);
  position: relative;
}

.section-title:after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background-color: var(--accent);
  margin: var(--spacing-sm) auto;
}

.text-primary { color: var(--primary) !important; }
.text-secondary { color: var(--secondary) !important; }
.text-accent { color: var(--accent) !important; }
.text-light { color: var(--light) !important; }
.text-dark { color: var(--dark) !important; }

.bg-primary { background-color: var(--primary) !important; }
.bg-secondary { background-color: var(--secondary) !important; }
.bg-accent { background-color: var(--accent) !important; }
.bg-light { background-color: var(--light) !important; }
.bg-dark { background-color: var(--dark) !important; }

.btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: var(--border-width) solid transparent;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: var(--border-radius);
  transition: all 0.3s ease;
  cursor: pointer;
}

.btn-primary {
  color: var(--light);
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:hover {
  background-color: darken(var(--primary), 10%);
  border-color: darken(var(--primary), 10%);
}

.btn-secondary {
  color: var(--light);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:hover {
  background-color: darken(var(--secondary), 10%);
  border-color: darken(var(--secondary), 10%);
}

.btn-accent {
  color: var(--light);
  background-color: var(--accent);
  border-color: var(--accent);
}

.btn-accent:hover {
  background-color: darken(var(--accent), 10%);
  border-color: darken(var(--accent), 10%);
}

.btn-outline-primary {
  color: var(--primary);
  background-color: transparent;
  border-color: var(--primary);
}

.btn-outline-primary:hover {
  color: var(--light);
  background-color: var(--primary);
  border-color: var(--primary);
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.resume h2 {
  color: #222;
  border-bottom: 2px solid #ccc;
  padding-bottom: 8px;
  margin-bottom: 12px;
  font-size: 1.75em;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
